import React from "react"
import withProfile from "hocs/withProfile"
import { Block } from "components/atoms"

class QrCode extends React.Component {
  render() {
    return <Block />
  }
}

export default withProfile(QrCode)

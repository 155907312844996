import React from "react"
import { auth, db } from "../firebase/config"
import { Block, Loader, Text } from "components/atoms"
import { navigate } from "gatsby"
import qs from "qs"
import get from "lodash/get"
import { WEBSITE } from "../helpers/constants"

// REDUX
import { getProfile } from "modules/auth/actions"
import { getCoupons } from "modules/notification/actions"
import { connect } from "react-redux"
import { selectGetAuthStatus, selectAuthData } from "modules/auth/selectors"
import { bindActionCreators } from "redux"

// import TransactionModal from 'containers/TransactionModal';
// import SurveyModal from 'components/SurveyModal';
import { compose } from "recompose"
import {
  TransactionModal,
  SurveyModal,
  WelcomeModal,
} from "components/molecules"

import { withTheme } from "hocs"
import CloudApi from "../api/cloudApi"
import { Header } from "components/molecules"

const withProfile = WrappedComponent => {
  class CheckAuthProfile extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        transactionData: null,
        latestSurvey: null,
        welcomeDeal: null,
        goToCheckEmail: null,
        user: null,
      }
    }

    componentDidMount() {
      const { getProfile, location } = this.props
      const query = qs.parse(location.search, { ignoreQueryPrefix: true })
      // Line login
      if (query.id_token && query.access_token) {
        return CloudApi.loginWithLine(
          { id_token: query.id_token, path: query.path },
          query.access_token
        ).then(({ token }) => {
          return auth
            .signInWithCustomToken(token)
            .then(() => navigate("/MyAccount", { replace: true }))
        })
      }

      if (query.customTokens) {
        return auth.signInWithCustomToken(query.customTokens).then(() =>
          navigate(`/MyAccount?path=${query.path || "null"}`, {
            replace: true,
          })
        )
      }

      auth.onAuthStateChanged(user => {
        const pathname = get(location, "pathname", "")

        if (user) {
          if (
            user.providerData.find(
              provider => provider.providerId === "password"
            ) &&
            !user.emailVerified
          ) {
            this.setState({ goToCheckEmail: true })
            return getProfile().then(data => {
              return auth.currentUser
                .sendEmailVerification({
                  url: `${WEBSITE}?customTokens=${get(
                    data.value,
                    "customTokens"
                  )}&path=${query.path || "null"}`,
                  handleCodeInApp: true,
                })
                .then(() => console.log("email sent."))
            })
          } else {
            this.setState({ goToCheckEmail: null })
          }
          if (query.path && query.path.includes("cashier:")) {
            getProfile(query.path)
            return navigate("/MyAccount", { replace: true })
          } else if (pathname.includes("qr-code")) {
            return navigate("/MyAccount", { replace: true })
          } else {
            getProfile()
          }
        } else {
          if (pathname.includes("brand")) {
            return navigate(`/brandlist/${location.search}`, { replace: true })
          }
          if (!query.path) return navigate(`/login`)
          else return navigate(`/login/${location.search}`)
        }
      })
    }

    componentWillReceiveProps(nextProps) {
      const { profile: nextProfile } = nextProps
      const { profile } = this.props
      if (
        nextProfile !== null &&
        get(nextProfile, "uid") !== get(profile, "uid", null)
      ) {
        this.connectRealTimeDb(get(nextProfile, "uid"))
      }
    }

    connectRealTimeDb = uid => {
      const { getProfile, getCoupons } = this.props
      const brandId = get(this.props, "pathContext.brandId")
      db.ref(`/transactions/${uid}`).on("value", snapshot => {
        const data = snapshot.val()
        const transactionBrandId = get(snapshot.val(), "brand.id")
        const type = get(snapshot.val(), "campaign.type")
        console.log("data", data)
        if (data && transactionBrandId === brandId) {
          if (type === "coupon") {
            getCoupons({ brandId: brandId, refresh: true })
          }
          getProfile()
          this.setState({ transactionData: data })
        }
      })
      db.ref(`/notifications/${uid}`).on("value", snapshot => {
        if (
          snapshot.val() &&
          snapshot.val().brandId === brandId &&
          snapshot.val().type === "survey"
        ) {
          this.setState({ latestSurvey: snapshot.val() })
        }

        if (
          snapshot.val() &&
          snapshot.val().brandId === brandId &&
          snapshot.val().type === "coupon"
        ) {
          getProfile()
          this.setState({ welcomeDeal: snapshot.val() })
        }
      })
    }

    setTransaction = () => {
      const { profile } = this.props
      this.setState({ transactionData: null })
      return db.ref(`/transactions/${profile.uid}`).remove()
    }

    clearNotification = () => {
      const { profile } = this.props
      this.setState({ latestSurvey: null, welcomeDeal: null })
      return db.ref(`/notifications/${profile.uid}`).remove()
    }

    render() {
      const { profile } = this.props
      const {
        transactionData,
        latestSurvey,
        welcomeDeal,
        goToCheckEmail,
      } = this.state

      if (!profile)
        return (
          <Block.MiddleCenter flex={1}>
            <Loader />
          </Block.MiddleCenter>
        )
      else
        return (
          <Block>
            {transactionData && !goToCheckEmail && (
              <TransactionModal
                profile={profile}
                transaction={transactionData}
                setTransactionNull={this.setTransaction}
              />
            )}
            {latestSurvey && !goToCheckEmail && (
              <SurveyModal
                open={!!latestSurvey}
                onClose={() => this.clearNotification()}
                uid={profile.uid}
                survey={latestSurvey}
              />
            )}
            {welcomeDeal && !goToCheckEmail && (
              <WelcomeModal
                onClose={() => this.clearNotification()}
                coupon={welcomeDeal}
              />
            )}
            {/* <WrappedComponent {...this.props} profile={profile} /> */}
            {goToCheckEmail ? (
              <Block>
                <Header />
                <Block mt={[150, 200]} textAlign={"center"}>
                  <Text>
                    {" "}
                    <h2>Please verify Your Email</h2>
                  </Text>
                </Block>
              </Block>
            ) : (
              <WrappedComponent {...this.props} profile={profile} />
            )}
          </Block>
        )
    }
  }

  const mapStateToProps = state => ({
    getProfileStatus: selectGetAuthStatus(state),
    profile: selectAuthData(state),
  })
  return compose(
    connect(
      mapStateToProps,
      dispatch =>
        bindActionCreators(
          {
            getProfile,
            getCoupons,
          },
          dispatch
        )
    ),
    withTheme()
  )(CheckAuthProfile)
}

export default withProfile
